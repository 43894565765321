module.exports = {
    "pages": [
        "SourceSansPro-Black.png"
    ],
    "chars": [
        {
            "id": 124,
            "index": 1143,
            "char": "|",
            "width": 12,
            "height": 76,
            "xoffset": 4,
            "yoffset": 2,
            "xadvance": 20,
            "chnl": 15,
            "x": 0,
            "y": 0,
            "page": 0
        },
        {
            "id": 40,
            "index": 1136,
            "char": "(",
            "width": 21,
            "height": 70,
            "xoffset": 3,
            "yoffset": 3,
            "xadvance": 26,
            "chnl": 15,
            "x": 0,
            "y": 77,
            "page": 0
        },
        {
            "id": 41,
            "index": 1137,
            "char": ")",
            "width": 21,
            "height": 70,
            "xoffset": 2,
            "yoffset": 3,
            "xadvance": 26,
            "chnl": 15,
            "x": 13,
            "y": 0,
            "page": 0
        },
        {
            "id": 106,
            "index": 37,
            "char": "j",
            "width": 25,
            "height": 70,
            "xoffset": -6,
            "yoffset": 3,
            "xadvance": 21,
            "chnl": 15,
            "x": 0,
            "y": 148,
            "page": 0
        },
        {
            "id": 92,
            "index": 1144,
            "char": "\\",
            "width": 25,
            "height": 67,
            "xoffset": 0,
            "yoffset": 5,
            "xadvance": 24,
            "chnl": 15,
            "x": 22,
            "y": 71,
            "page": 0
        },
        {
            "id": 47,
            "index": 1142,
            "char": "/",
            "width": 25,
            "height": 67,
            "xoffset": -1,
            "yoffset": 5,
            "xadvance": 24,
            "chnl": 15,
            "x": 35,
            "y": 0,
            "page": 0
        },
        {
            "id": 123,
            "index": 1140,
            "char": "{",
            "width": 24,
            "height": 66,
            "xoffset": 0,
            "yoffset": 5,
            "xadvance": 26,
            "chnl": 15,
            "x": 0,
            "y": 219,
            "page": 0
        },
        {
            "id": 125,
            "index": 1141,
            "char": "}",
            "width": 24,
            "height": 66,
            "xoffset": 2,
            "yoffset": 5,
            "xadvance": 26,
            "chnl": 15,
            "x": 0,
            "y": 286,
            "page": 0
        },
        {
            "id": 64,
            "index": 1174,
            "char": "@",
            "width": 64,
            "height": 66,
            "xoffset": 1,
            "yoffset": 7,
            "xadvance": 67,
            "chnl": 15,
            "x": 0,
            "y": 353,
            "page": 0
        },
        {
            "id": 91,
            "index": 1138,
            "char": "[",
            "width": 20,
            "height": 66,
            "xoffset": 4,
            "yoffset": 5,
            "xadvance": 26,
            "chnl": 15,
            "x": 0,
            "y": 420,
            "page": 0
        },
        {
            "id": 93,
            "index": 1139,
            "char": "]",
            "width": 20,
            "height": 66,
            "xoffset": 2,
            "yoffset": 5,
            "xadvance": 26,
            "chnl": 15,
            "x": 21,
            "y": 420,
            "page": 0
        },
        {
            "id": 81,
            "index": 18,
            "char": "Q",
            "width": 51,
            "height": 65,
            "xoffset": 1,
            "yoffset": 8,
            "xadvance": 50,
            "chnl": 15,
            "x": 42,
            "y": 420,
            "page": 0
        },
        {
            "id": 36,
            "index": 1660,
            "char": "$",
            "width": 36,
            "height": 65,
            "xoffset": 0,
            "yoffset": 2,
            "xadvance": 39,
            "chnl": 15,
            "x": 25,
            "y": 219,
            "page": 0
        },
        {
            "id": 37,
            "index": 1684,
            "char": "%",
            "width": 63,
            "height": 53,
            "xoffset": 0,
            "yoffset": 8,
            "xadvance": 63,
            "chnl": 15,
            "x": 25,
            "y": 285,
            "page": 0
        },
        {
            "id": 87,
            "index": 24,
            "char": "W",
            "width": 62,
            "height": 51,
            "xoffset": -1,
            "yoffset": 9,
            "xadvance": 59,
            "chnl": 15,
            "x": 65,
            "y": 339,
            "page": 0
        },
        {
            "id": 109,
            "index": 40,
            "char": "m",
            "width": 58,
            "height": 41,
            "xoffset": 2,
            "yoffset": 19,
            "xadvance": 62,
            "chnl": 15,
            "x": 26,
            "y": 139,
            "page": 0
        },
        {
            "id": 119,
            "index": 50,
            "char": "w",
            "width": 58,
            "height": 40,
            "xoffset": 0,
            "yoffset": 20,
            "xadvance": 57,
            "chnl": 15,
            "x": 48,
            "y": 68,
            "page": 0
        },
        {
            "id": 105,
            "index": 36,
            "char": "i",
            "width": 18,
            "height": 57,
            "xoffset": 1,
            "yoffset": 3,
            "xadvance": 21,
            "chnl": 15,
            "x": 61,
            "y": 0,
            "page": 0
        },
        {
            "id": 103,
            "index": 34,
            "char": "g",
            "width": 40,
            "height": 56,
            "xoffset": 0,
            "yoffset": 19,
            "xadvance": 39,
            "chnl": 15,
            "x": 80,
            "y": 0,
            "page": 0
        },
        {
            "id": 59,
            "index": 1105,
            "char": ";",
            "width": 21,
            "height": 55,
            "xoffset": 1,
            "yoffset": 20,
            "xadvance": 23,
            "chnl": 15,
            "x": 62,
            "y": 181,
            "page": 0
        },
        {
            "id": 100,
            "index": 31,
            "char": "d",
            "width": 39,
            "height": 55,
            "xoffset": 1,
            "yoffset": 6,
            "xadvance": 42,
            "chnl": 15,
            "x": 84,
            "y": 181,
            "page": 0
        },
        {
            "id": 108,
            "index": 39,
            "char": "l",
            "width": 21,
            "height": 55,
            "xoffset": 2,
            "yoffset": 6,
            "xadvance": 21,
            "chnl": 15,
            "x": 85,
            "y": 109,
            "page": 0
        },
        {
            "id": 98,
            "index": 29,
            "char": "b",
            "width": 39,
            "height": 55,
            "xoffset": 2,
            "yoffset": 6,
            "xadvance": 42,
            "chnl": 15,
            "x": 89,
            "y": 237,
            "page": 0
        },
        {
            "id": 102,
            "index": 33,
            "char": "f",
            "width": 31,
            "height": 55,
            "xoffset": 0,
            "yoffset": 5,
            "xadvance": 26,
            "chnl": 15,
            "x": 107,
            "y": 57,
            "page": 0
        },
        {
            "id": 107,
            "index": 38,
            "char": "k",
            "width": 40,
            "height": 54,
            "xoffset": 2,
            "yoffset": 6,
            "xadvance": 41,
            "chnl": 15,
            "x": 121,
            "y": 0,
            "page": 0
        },
        {
            "id": 113,
            "index": 44,
            "char": "q",
            "width": 39,
            "height": 54,
            "xoffset": 1,
            "yoffset": 19,
            "xadvance": 42,
            "chnl": 15,
            "x": 107,
            "y": 113,
            "page": 0
        },
        {
            "id": 63,
            "index": 1109,
            "char": "?",
            "width": 33,
            "height": 54,
            "xoffset": 1,
            "yoffset": 7,
            "xadvance": 34,
            "chnl": 15,
            "x": 139,
            "y": 55,
            "page": 0
        },
        {
            "id": 121,
            "index": 52,
            "char": "y",
            "width": 41,
            "height": 54,
            "xoffset": -1,
            "yoffset": 20,
            "xadvance": 39,
            "chnl": 15,
            "x": 162,
            "y": 0,
            "page": 0
        },
        {
            "id": 112,
            "index": 43,
            "char": "p",
            "width": 39,
            "height": 54,
            "xoffset": 2,
            "yoffset": 19,
            "xadvance": 42,
            "chnl": 15,
            "x": 124,
            "y": 168,
            "page": 0
        },
        {
            "id": 104,
            "index": 35,
            "char": "h",
            "width": 38,
            "height": 54,
            "xoffset": 2,
            "yoffset": 6,
            "xadvance": 42,
            "chnl": 15,
            "x": 147,
            "y": 110,
            "page": 0
        },
        {
            "id": 38,
            "index": 1057,
            "char": "&",
            "width": 51,
            "height": 53,
            "xoffset": 0,
            "yoffset": 8,
            "xadvance": 50,
            "chnl": 15,
            "x": 173,
            "y": 55,
            "page": 0
        },
        {
            "id": 83,
            "index": 20,
            "char": "S",
            "width": 40,
            "height": 53,
            "xoffset": 0,
            "yoffset": 8,
            "xadvance": 41,
            "chnl": 15,
            "x": 204,
            "y": 0,
            "page": 0
        },
        {
            "id": 33,
            "index": 1107,
            "char": "!",
            "width": 18,
            "height": 53,
            "xoffset": 4,
            "yoffset": 8,
            "xadvance": 26,
            "chnl": 15,
            "x": 94,
            "y": 391,
            "page": 0
        },
        {
            "id": 67,
            "index": 4,
            "char": "C",
            "width": 42,
            "height": 53,
            "xoffset": 1,
            "yoffset": 8,
            "xadvance": 42,
            "chnl": 15,
            "x": 94,
            "y": 445,
            "page": 0
        },
        {
            "id": 79,
            "index": 16,
            "char": "O",
            "width": 47,
            "height": 53,
            "xoffset": 1,
            "yoffset": 8,
            "xadvance": 50,
            "chnl": 15,
            "x": 113,
            "y": 391,
            "page": 0
        },
        {
            "id": 71,
            "index": 8,
            "char": "G",
            "width": 43,
            "height": 53,
            "xoffset": 1,
            "yoffset": 8,
            "xadvance": 47,
            "chnl": 15,
            "x": 137,
            "y": 445,
            "page": 0
        },
        {
            "id": 74,
            "index": 11,
            "char": "J",
            "width": 36,
            "height": 52,
            "xoffset": -1,
            "yoffset": 9,
            "xadvance": 37,
            "chnl": 15,
            "x": 128,
            "y": 293,
            "page": 0
        },
        {
            "id": 85,
            "index": 22,
            "char": "U",
            "width": 43,
            "height": 52,
            "xoffset": 3,
            "yoffset": 9,
            "xadvance": 48,
            "chnl": 15,
            "x": 129,
            "y": 223,
            "page": 0
        },
        {
            "id": 72,
            "index": 9,
            "char": "H",
            "width": 43,
            "height": 51,
            "xoffset": 3,
            "yoffset": 9,
            "xadvance": 49,
            "chnl": 15,
            "x": 164,
            "y": 165,
            "page": 0
        },
        {
            "id": 73,
            "index": 10,
            "char": "I",
            "width": 16,
            "height": 51,
            "xoffset": 3,
            "yoffset": 9,
            "xadvance": 23,
            "chnl": 15,
            "x": 186,
            "y": 109,
            "page": 0
        },
        {
            "id": 68,
            "index": 5,
            "char": "D",
            "width": 42,
            "height": 51,
            "xoffset": 3,
            "yoffset": 9,
            "xadvance": 46,
            "chnl": 15,
            "x": 203,
            "y": 109,
            "page": 0
        },
        {
            "id": 75,
            "index": 12,
            "char": "K",
            "width": 45,
            "height": 51,
            "xoffset": 3,
            "yoffset": 9,
            "xadvance": 45,
            "chnl": 15,
            "x": 225,
            "y": 54,
            "page": 0
        },
        {
            "id": 76,
            "index": 13,
            "char": "L",
            "width": 34,
            "height": 51,
            "xoffset": 3,
            "yoffset": 9,
            "xadvance": 38,
            "chnl": 15,
            "x": 245,
            "y": 0,
            "page": 0
        },
        {
            "id": 77,
            "index": 14,
            "char": "M",
            "width": 50,
            "height": 51,
            "xoffset": 3,
            "yoffset": 9,
            "xadvance": 56,
            "chnl": 15,
            "x": 161,
            "y": 346,
            "page": 0
        },
        {
            "id": 78,
            "index": 15,
            "char": "N",
            "width": 42,
            "height": 51,
            "xoffset": 3,
            "yoffset": 9,
            "xadvance": 48,
            "chnl": 15,
            "x": 165,
            "y": 276,
            "page": 0
        },
        {
            "id": 66,
            "index": 3,
            "char": "B",
            "width": 41,
            "height": 51,
            "xoffset": 3,
            "yoffset": 9,
            "xadvance": 44,
            "chnl": 15,
            "x": 173,
            "y": 217,
            "page": 0
        },
        {
            "id": 80,
            "index": 17,
            "char": "P",
            "width": 40,
            "height": 51,
            "xoffset": 3,
            "yoffset": 9,
            "xadvance": 44,
            "chnl": 15,
            "x": 208,
            "y": 161,
            "page": 0
        },
        {
            "id": 65,
            "index": 2,
            "char": "A",
            "width": 47,
            "height": 51,
            "xoffset": -3,
            "yoffset": 9,
            "xadvance": 42,
            "chnl": 15,
            "x": 246,
            "y": 106,
            "page": 0
        },
        {
            "id": 82,
            "index": 19,
            "char": "R",
            "width": 43,
            "height": 51,
            "xoffset": 3,
            "yoffset": 9,
            "xadvance": 45,
            "chnl": 15,
            "x": 271,
            "y": 52,
            "page": 0
        },
        {
            "id": 35,
            "index": 1176,
            "char": "#",
            "width": 38,
            "height": 51,
            "xoffset": 0,
            "yoffset": 9,
            "xadvance": 39,
            "chnl": 15,
            "x": 280,
            "y": 0,
            "page": 0
        },
        {
            "id": 84,
            "index": 21,
            "char": "T",
            "width": 41,
            "height": 51,
            "xoffset": 0,
            "yoffset": 9,
            "xadvance": 41,
            "chnl": 15,
            "x": 208,
            "y": 269,
            "page": 0
        },
        {
            "id": 70,
            "index": 7,
            "char": "F",
            "width": 35,
            "height": 51,
            "xoffset": 3,
            "yoffset": 9,
            "xadvance": 39,
            "chnl": 15,
            "x": 215,
            "y": 213,
            "page": 0
        },
        {
            "id": 86,
            "index": 23,
            "char": "V",
            "width": 47,
            "height": 51,
            "xoffset": -3,
            "yoffset": 9,
            "xadvance": 41,
            "chnl": 15,
            "x": 249,
            "y": 158,
            "page": 0
        },
        {
            "id": 48,
            "index": 1058,
            "char": "0",
            "width": 38,
            "height": 51,
            "xoffset": 0,
            "yoffset": 9,
            "xadvance": 39,
            "chnl": 15,
            "x": 294,
            "y": 104,
            "page": 0
        },
        {
            "id": 88,
            "index": 25,
            "char": "X",
            "width": 45,
            "height": 51,
            "xoffset": -1,
            "yoffset": 9,
            "xadvance": 42,
            "chnl": 15,
            "x": 315,
            "y": 52,
            "page": 0
        },
        {
            "id": 89,
            "index": 26,
            "char": "Y",
            "width": 45,
            "height": 51,
            "xoffset": -3,
            "yoffset": 9,
            "xadvance": 39,
            "chnl": 15,
            "x": 319,
            "y": 0,
            "page": 0
        },
        {
            "id": 90,
            "index": 27,
            "char": "Z",
            "width": 39,
            "height": 51,
            "xoffset": 0,
            "yoffset": 9,
            "xadvance": 39,
            "chnl": 15,
            "x": 181,
            "y": 398,
            "page": 0
        },
        {
            "id": 50,
            "index": 1060,
            "char": "2",
            "width": 38,
            "height": 51,
            "xoffset": 0,
            "yoffset": 9,
            "xadvance": 39,
            "chnl": 15,
            "x": 181,
            "y": 450,
            "page": 0
        },
        {
            "id": 69,
            "index": 6,
            "char": "E",
            "width": 35,
            "height": 51,
            "xoffset": 3,
            "yoffset": 9,
            "xadvance": 40,
            "chnl": 15,
            "x": 220,
            "y": 450,
            "page": 0
        },
        {
            "id": 56,
            "index": 1066,
            "char": "8",
            "width": 37,
            "height": 51,
            "xoffset": 1,
            "yoffset": 9,
            "xadvance": 39,
            "chnl": 15,
            "x": 212,
            "y": 321,
            "page": 0
        },
        {
            "id": 51,
            "index": 1061,
            "char": "3",
            "width": 38,
            "height": 51,
            "xoffset": -1,
            "yoffset": 9,
            "xadvance": 39,
            "chnl": 15,
            "x": 221,
            "y": 373,
            "page": 0
        },
        {
            "id": 53,
            "index": 1063,
            "char": "5",
            "width": 38,
            "height": 51,
            "xoffset": 0,
            "yoffset": 10,
            "xadvance": 39,
            "chnl": 15,
            "x": 256,
            "y": 425,
            "page": 0
        },
        {
            "id": 54,
            "index": 1064,
            "char": "6",
            "width": 38,
            "height": 51,
            "xoffset": 1,
            "yoffset": 9,
            "xadvance": 39,
            "chnl": 15,
            "x": 250,
            "y": 265,
            "page": 0
        },
        {
            "id": 57,
            "index": 1067,
            "char": "9",
            "width": 38,
            "height": 51,
            "xoffset": 0,
            "yoffset": 9,
            "xadvance": 39,
            "chnl": 15,
            "x": 251,
            "y": 210,
            "page": 0
        },
        {
            "id": 52,
            "index": 1062,
            "char": "4",
            "width": 40,
            "height": 50,
            "xoffset": -1,
            "yoffset": 10,
            "xadvance": 39,
            "chnl": 15,
            "x": 250,
            "y": 317,
            "page": 0
        },
        {
            "id": 116,
            "index": 47,
            "char": "t",
            "width": 30,
            "height": 50,
            "xoffset": -1,
            "yoffset": 11,
            "xadvance": 29,
            "chnl": 15,
            "x": 289,
            "y": 262,
            "page": 0
        },
        {
            "id": 55,
            "index": 1065,
            "char": "7",
            "width": 37,
            "height": 50,
            "xoffset": 1,
            "yoffset": 10,
            "xadvance": 39,
            "chnl": 15,
            "x": 290,
            "y": 210,
            "page": 0
        },
        {
            "id": 49,
            "index": 1059,
            "char": "1",
            "width": 34,
            "height": 50,
            "xoffset": 3,
            "yoffset": 10,
            "xadvance": 39,
            "chnl": 15,
            "x": 297,
            "y": 156,
            "page": 0
        },
        {
            "id": 115,
            "index": 46,
            "char": "s",
            "width": 33,
            "height": 42,
            "xoffset": -1,
            "yoffset": 19,
            "xadvance": 33,
            "chnl": 15,
            "x": 89,
            "y": 293,
            "page": 0
        },
        {
            "id": 97,
            "index": 28,
            "char": "a",
            "width": 36,
            "height": 42,
            "xoffset": 1,
            "yoffset": 19,
            "xadvance": 39,
            "chnl": 15,
            "x": 260,
            "y": 368,
            "page": 0
        },
        {
            "id": 99,
            "index": 30,
            "char": "c",
            "width": 34,
            "height": 42,
            "xoffset": 0,
            "yoffset": 19,
            "xadvance": 34,
            "chnl": 15,
            "x": 291,
            "y": 313,
            "page": 0
        },
        {
            "id": 111,
            "index": 42,
            "char": "o",
            "width": 40,
            "height": 42,
            "xoffset": 0,
            "yoffset": 19,
            "xadvance": 40,
            "chnl": 15,
            "x": 320,
            "y": 261,
            "page": 0
        },
        {
            "id": 101,
            "index": 32,
            "char": "e",
            "width": 37,
            "height": 42,
            "xoffset": 0,
            "yoffset": 19,
            "xadvance": 38,
            "chnl": 15,
            "x": 328,
            "y": 207,
            "page": 0
        },
        {
            "id": 118,
            "index": 49,
            "char": "v",
            "width": 41,
            "height": 40,
            "xoffset": -1,
            "yoffset": 20,
            "xadvance": 39,
            "chnl": 15,
            "x": 332,
            "y": 156,
            "page": 0
        },
        {
            "id": 120,
            "index": 51,
            "char": "x",
            "width": 41,
            "height": 40,
            "xoffset": -1,
            "yoffset": 20,
            "xadvance": 39,
            "chnl": 15,
            "x": 333,
            "y": 104,
            "page": 0
        },
        {
            "id": 110,
            "index": 41,
            "char": "n",
            "width": 38,
            "height": 41,
            "xoffset": 2,
            "yoffset": 19,
            "xadvance": 42,
            "chnl": 15,
            "x": 361,
            "y": 52,
            "page": 0
        },
        {
            "id": 114,
            "index": 45,
            "char": "r",
            "width": 30,
            "height": 41,
            "xoffset": 2,
            "yoffset": 19,
            "xadvance": 30,
            "chnl": 15,
            "x": 128,
            "y": 346,
            "page": 0
        },
        {
            "id": 117,
            "index": 48,
            "char": "u",
            "width": 38,
            "height": 41,
            "xoffset": 2,
            "yoffset": 20,
            "xadvance": 42,
            "chnl": 15,
            "x": 365,
            "y": 0,
            "page": 0
        },
        {
            "id": 58,
            "index": 1104,
            "char": ":",
            "width": 18,
            "height": 41,
            "xoffset": 2,
            "yoffset": 20,
            "xadvance": 23,
            "chnl": 15,
            "x": 161,
            "y": 398,
            "page": 0
        },
        {
            "id": 122,
            "index": 53,
            "char": "z",
            "width": 34,
            "height": 40,
            "xoffset": 1,
            "yoffset": 20,
            "xadvance": 34,
            "chnl": 15,
            "x": 295,
            "y": 411,
            "page": 0
        },
        {
            "id": 126,
            "index": 1718,
            "char": "~",
            "width": 39,
            "height": 18,
            "xoffset": 0,
            "yoffset": 25,
            "xadvance": 39,
            "chnl": 15,
            "x": 42,
            "y": 486,
            "page": 0
        },
        {
            "id": 43,
            "index": 1705,
            "char": "+",
            "width": 38,
            "height": 39,
            "xoffset": 0,
            "yoffset": 15,
            "xadvance": 39,
            "chnl": 15,
            "x": 297,
            "y": 356,
            "page": 0
        },
        {
            "id": 95,
            "index": 1133,
            "char": "_",
            "width": 38,
            "height": 11,
            "xoffset": -1,
            "yoffset": 60,
            "xadvance": 36,
            "chnl": 15,
            "x": 85,
            "y": 168,
            "page": 0
        },
        {
            "id": 62,
            "index": 1712,
            "char": ">",
            "width": 38,
            "height": 38,
            "xoffset": 0,
            "yoffset": 15,
            "xadvance": 39,
            "chnl": 15,
            "x": 326,
            "y": 304,
            "page": 0
        },
        {
            "id": 61,
            "index": 1710,
            "char": "=",
            "width": 38,
            "height": 30,
            "xoffset": 0,
            "yoffset": 19,
            "xadvance": 39,
            "chnl": 15,
            "x": 361,
            "y": 250,
            "page": 0
        },
        {
            "id": 60,
            "index": 1711,
            "char": "<",
            "width": 38,
            "height": 38,
            "xoffset": 0,
            "yoffset": 15,
            "xadvance": 39,
            "chnl": 15,
            "x": 366,
            "y": 197,
            "page": 0
        },
        {
            "id": 94,
            "index": 1716,
            "char": "^",
            "width": 36,
            "height": 33,
            "xoffset": 1,
            "yoffset": 8,
            "xadvance": 39,
            "chnl": 15,
            "x": 374,
            "y": 145,
            "page": 0
        },
        {
            "id": 34,
            "index": 1112,
            "char": "\"",
            "width": 35,
            "height": 30,
            "xoffset": 3,
            "yoffset": 7,
            "xadvance": 42,
            "chnl": 15,
            "x": 26,
            "y": 181,
            "page": 0
        },
        {
            "id": 42,
            "index": 1146,
            "char": "*",
            "width": 34,
            "height": 32,
            "xoffset": 0,
            "yoffset": 5,
            "xadvance": 34,
            "chnl": 15,
            "x": 375,
            "y": 94,
            "page": 0
        },
        {
            "id": 44,
            "index": 1103,
            "char": ",",
            "width": 21,
            "height": 33,
            "xoffset": 1,
            "yoffset": 42,
            "xadvance": 23,
            "chnl": 15,
            "x": 62,
            "y": 237,
            "page": 0
        },
        {
            "id": 39,
            "index": 1111,
            "char": "'",
            "width": 16,
            "height": 30,
            "xoffset": 3,
            "yoffset": 7,
            "xadvance": 23,
            "chnl": 15,
            "x": 400,
            "y": 42,
            "page": 0
        },
        {
            "id": 96,
            "index": 1761,
            "char": "`",
            "width": 24,
            "height": 24,
            "xoffset": 3,
            "yoffset": -5,
            "xadvance": 40,
            "chnl": 15,
            "x": 65,
            "y": 391,
            "page": 0
        },
        {
            "id": 45,
            "index": 1123,
            "char": "-",
            "width": 22,
            "height": 13,
            "xoffset": 1,
            "yoffset": 33,
            "xadvance": 24,
            "chnl": 15,
            "x": 62,
            "y": 271,
            "page": 0
        },
        {
            "id": 46,
            "index": 1102,
            "char": ".",
            "width": 18,
            "height": 19,
            "xoffset": 2,
            "yoffset": 42,
            "xadvance": 23,
            "chnl": 15,
            "x": 221,
            "y": 425,
            "page": 0
        },
        {
            "id": 32,
            "index": 1,
            "char": " ",
            "width": 0,
            "height": 0,
            "xoffset": -2,
            "yoffset": 56,
            "xadvance": 14,
            "chnl": 15,
            "x": 249,
            "y": 210,
            "page": 0
        }
    ],
    "info": {
        "face": "SourceSansPro-Black",
        "size": 72,
        "bold": 0,
        "italic": 0,
        "charset": [
            " ",
            "!",
            "\"",
            "#",
            "$",
            "%",
            "&",
            "'",
            "(",
            ")",
            "*",
            "+",
            ",",
            "-",
            ".",
            "/",
            "0",
            "1",
            "2",
            "3",
            "4",
            "5",
            "6",
            "7",
            "8",
            "9",
            ":",
            ";",
            "<",
            "=",
            ">",
            "?",
            "@",
            "A",
            "B",
            "C",
            "D",
            "E",
            "F",
            "G",
            "H",
            "I",
            "J",
            "K",
            "L",
            "M",
            "N",
            "O",
            "P",
            "Q",
            "R",
            "S",
            "T",
            "U",
            "V",
            "W",
            "X",
            "Y",
            "Z",
            "[",
            "\\",
            "]",
            "^",
            "_",
            "`",
            "a",
            "b",
            "c",
            "d",
            "e",
            "f",
            "g",
            "h",
            "i",
            "j",
            "k",
            "l",
            "m",
            "n",
            "o",
            "p",
            "q",
            "r",
            "s",
            "t",
            "u",
            "v",
            "w",
            "x",
            "y",
            "z",
            "{",
            "|",
            "}",
            "~"
        ],
        "unicode": 1,
        "stretchH": 100,
        "smooth": 1,
        "aa": 1,
        "padding": [
            0,
            0,
            0,
            0
        ],
        "spacing": [
            0,
            0
        ]
    },
    "common": {
        "lineHeight": 72,
        "base": 56,
        "scaleW": 512,
        "scaleH": 512,
        "pages": 1,
        "packed": 0,
        "alphaChnl": 0,
        "redChnl": 0,
        "greenChnl": 0,
        "blueChnl": 0
    },
    "distanceField": {
        "fieldType": "msdf",
        "distanceRange": 4
    },
    "kernings": [
        {
            "first": 34,
            "second": 44,
            "amount": -10
        },
        {
            "first": 34,
            "second": 46,
            "amount": -10
        },
        {
            "first": 34,
            "second": 65,
            "amount": -6
        },
        {
            "first": 34,
            "second": 74,
            "amount": -9
        },
        {
            "first": 34,
            "second": 83,
            "amount": -1
        },
        {
            "first": 34,
            "second": 88,
            "amount": -1
        },
        {
            "first": 34,
            "second": 99,
            "amount": -1
        },
        {
            "first": 34,
            "second": 100,
            "amount": -1
        },
        {
            "first": 34,
            "second": 101,
            "amount": -1
        },
        {
            "first": 34,
            "second": 111,
            "amount": -1
        },
        {
            "first": 34,
            "second": 113,
            "amount": -1
        },
        {
            "first": 34,
            "second": 115,
            "amount": -1
        },
        {
            "first": 39,
            "second": 44,
            "amount": -10
        },
        {
            "first": 39,
            "second": 46,
            "amount": -10
        },
        {
            "first": 39,
            "second": 65,
            "amount": -6
        },
        {
            "first": 39,
            "second": 74,
            "amount": -9
        },
        {
            "first": 39,
            "second": 83,
            "amount": -1
        },
        {
            "first": 39,
            "second": 88,
            "amount": -1
        },
        {
            "first": 39,
            "second": 99,
            "amount": -1
        },
        {
            "first": 39,
            "second": 100,
            "amount": -1
        },
        {
            "first": 39,
            "second": 101,
            "amount": -1
        },
        {
            "first": 39,
            "second": 111,
            "amount": -1
        },
        {
            "first": 39,
            "second": 113,
            "amount": -1
        },
        {
            "first": 39,
            "second": 115,
            "amount": -1
        },
        {
            "first": 40,
            "second": 74,
            "amount": -1
        },
        {
            "first": 40,
            "second": 106,
            "amount": 6
        },
        {
            "first": 44,
            "second": 34,
            "amount": -10
        },
        {
            "first": 44,
            "second": 39,
            "amount": -10
        },
        {
            "first": 44,
            "second": 42,
            "amount": -13
        },
        {
            "first": 44,
            "second": 84,
            "amount": -7
        },
        {
            "first": 44,
            "second": 85,
            "amount": -2
        },
        {
            "first": 44,
            "second": 86,
            "amount": -6
        },
        {
            "first": 44,
            "second": 87,
            "amount": -3
        },
        {
            "first": 44,
            "second": 89,
            "amount": -9
        },
        {
            "first": 44,
            "second": 99,
            "amount": -1
        },
        {
            "first": 44,
            "second": 100,
            "amount": -1
        },
        {
            "first": 44,
            "second": 101,
            "amount": -1
        },
        {
            "first": 44,
            "second": 106,
            "amount": 3
        },
        {
            "first": 44,
            "second": 111,
            "amount": -1
        },
        {
            "first": 44,
            "second": 113,
            "amount": -1
        },
        {
            "first": 44,
            "second": 116,
            "amount": -5
        },
        {
            "first": 44,
            "second": 118,
            "amount": -5
        },
        {
            "first": 44,
            "second": 119,
            "amount": -3
        },
        {
            "first": 44,
            "second": 121,
            "amount": -1
        },
        {
            "first": 45,
            "second": 74,
            "amount": -1
        },
        {
            "first": 45,
            "second": 83,
            "amount": -2
        },
        {
            "first": 45,
            "second": 84,
            "amount": -4
        },
        {
            "first": 45,
            "second": 86,
            "amount": -1
        },
        {
            "first": 45,
            "second": 87,
            "amount": -1
        },
        {
            "first": 45,
            "second": 88,
            "amount": -3
        },
        {
            "first": 45,
            "second": 89,
            "amount": -6
        },
        {
            "first": 45,
            "second": 90,
            "amount": -1
        },
        {
            "first": 45,
            "second": 116,
            "amount": -1
        },
        {
            "first": 45,
            "second": 118,
            "amount": -1
        },
        {
            "first": 45,
            "second": 120,
            "amount": -1
        },
        {
            "first": 46,
            "second": 34,
            "amount": -10
        },
        {
            "first": 46,
            "second": 39,
            "amount": -10
        },
        {
            "first": 46,
            "second": 42,
            "amount": -13
        },
        {
            "first": 46,
            "second": 84,
            "amount": -7
        },
        {
            "first": 46,
            "second": 85,
            "amount": -2
        },
        {
            "first": 46,
            "second": 86,
            "amount": -6
        },
        {
            "first": 46,
            "second": 87,
            "amount": -3
        },
        {
            "first": 46,
            "second": 89,
            "amount": -9
        },
        {
            "first": 46,
            "second": 99,
            "amount": -1
        },
        {
            "first": 46,
            "second": 100,
            "amount": -1
        },
        {
            "first": 46,
            "second": 101,
            "amount": -1
        },
        {
            "first": 46,
            "second": 106,
            "amount": 3
        },
        {
            "first": 46,
            "second": 111,
            "amount": -1
        },
        {
            "first": 46,
            "second": 113,
            "amount": -1
        },
        {
            "first": 46,
            "second": 116,
            "amount": -5
        },
        {
            "first": 46,
            "second": 118,
            "amount": -5
        },
        {
            "first": 46,
            "second": 119,
            "amount": -3
        },
        {
            "first": 46,
            "second": 121,
            "amount": -1
        },
        {
            "first": 47,
            "second": 65,
            "amount": -3
        },
        {
            "first": 47,
            "second": 67,
            "amount": -1
        },
        {
            "first": 47,
            "second": 71,
            "amount": -1
        },
        {
            "first": 47,
            "second": 74,
            "amount": -6
        },
        {
            "first": 47,
            "second": 79,
            "amount": -1
        },
        {
            "first": 47,
            "second": 81,
            "amount": -1
        },
        {
            "first": 47,
            "second": 97,
            "amount": -2
        },
        {
            "first": 47,
            "second": 99,
            "amount": -1
        },
        {
            "first": 47,
            "second": 100,
            "amount": -1
        },
        {
            "first": 47,
            "second": 101,
            "amount": -1
        },
        {
            "first": 47,
            "second": 103,
            "amount": -1
        },
        {
            "first": 47,
            "second": 111,
            "amount": -1
        },
        {
            "first": 47,
            "second": 113,
            "amount": -1
        },
        {
            "first": 47,
            "second": 116,
            "amount": -1
        },
        {
            "first": 47,
            "second": 117,
            "amount": -1
        },
        {
            "first": 58,
            "second": 42,
            "amount": -1
        },
        {
            "first": 58,
            "second": 89,
            "amount": -3
        },
        {
            "first": 58,
            "second": 106,
            "amount": 0
        },
        {
            "first": 59,
            "second": 42,
            "amount": -1
        },
        {
            "first": 59,
            "second": 89,
            "amount": -3
        },
        {
            "first": 59,
            "second": 106,
            "amount": 0
        },
        {
            "first": 65,
            "second": 34,
            "amount": -6
        },
        {
            "first": 65,
            "second": 39,
            "amount": -6
        },
        {
            "first": 65,
            "second": 42,
            "amount": -6
        },
        {
            "first": 65,
            "second": 63,
            "amount": -3
        },
        {
            "first": 65,
            "second": 67,
            "amount": -1
        },
        {
            "first": 65,
            "second": 71,
            "amount": -1
        },
        {
            "first": 65,
            "second": 79,
            "amount": -1
        },
        {
            "first": 65,
            "second": 81,
            "amount": -1
        },
        {
            "first": 65,
            "second": 84,
            "amount": -6
        },
        {
            "first": 65,
            "second": 85,
            "amount": -1
        },
        {
            "first": 65,
            "second": 86,
            "amount": -1
        },
        {
            "first": 65,
            "second": 88,
            "amount": -1
        },
        {
            "first": 65,
            "second": 89,
            "amount": -1
        },
        {
            "first": 65,
            "second": 92,
            "amount": -3
        },
        {
            "first": 65,
            "second": 97,
            "amount": 1
        },
        {
            "first": 65,
            "second": 102,
            "amount": -1
        },
        {
            "first": 65,
            "second": 115,
            "amount": 1
        },
        {
            "first": 65,
            "second": 116,
            "amount": -1
        },
        {
            "first": 65,
            "second": 118,
            "amount": -1
        },
        {
            "first": 65,
            "second": 119,
            "amount": -1
        },
        {
            "first": 65,
            "second": 120,
            "amount": -1
        },
        {
            "first": 65,
            "second": 121,
            "amount": -1
        },
        {
            "first": 66,
            "second": 42,
            "amount": -2
        },
        {
            "first": 66,
            "second": 74,
            "amount": -1
        },
        {
            "first": 66,
            "second": 83,
            "amount": -1
        },
        {
            "first": 66,
            "second": 84,
            "amount": -2
        },
        {
            "first": 66,
            "second": 86,
            "amount": -1
        },
        {
            "first": 66,
            "second": 87,
            "amount": -1
        },
        {
            "first": 66,
            "second": 88,
            "amount": -1
        },
        {
            "first": 66,
            "second": 89,
            "amount": -1
        },
        {
            "first": 66,
            "second": 90,
            "amount": -1
        },
        {
            "first": 66,
            "second": 116,
            "amount": -1
        },
        {
            "first": 66,
            "second": 118,
            "amount": -1
        },
        {
            "first": 66,
            "second": 119,
            "amount": -1
        },
        {
            "first": 66,
            "second": 120,
            "amount": -1
        },
        {
            "first": 66,
            "second": 121,
            "amount": -1
        },
        {
            "first": 67,
            "second": 45,
            "amount": -3
        },
        {
            "first": 67,
            "second": 67,
            "amount": -3
        },
        {
            "first": 67,
            "second": 71,
            "amount": -3
        },
        {
            "first": 67,
            "second": 74,
            "amount": -1
        },
        {
            "first": 67,
            "second": 79,
            "amount": -3
        },
        {
            "first": 67,
            "second": 81,
            "amount": -3
        },
        {
            "first": 67,
            "second": 83,
            "amount": -2
        },
        {
            "first": 67,
            "second": 84,
            "amount": -1
        },
        {
            "first": 67,
            "second": 85,
            "amount": -1
        },
        {
            "first": 67,
            "second": 86,
            "amount": -1
        },
        {
            "first": 67,
            "second": 87,
            "amount": -1
        },
        {
            "first": 67,
            "second": 88,
            "amount": -1
        },
        {
            "first": 67,
            "second": 89,
            "amount": -1
        },
        {
            "first": 67,
            "second": 90,
            "amount": -1
        },
        {
            "first": 67,
            "second": 103,
            "amount": -1
        },
        {
            "first": 67,
            "second": 116,
            "amount": -3
        },
        {
            "first": 67,
            "second": 117,
            "amount": -1
        },
        {
            "first": 67,
            "second": 118,
            "amount": -1
        },
        {
            "first": 67,
            "second": 119,
            "amount": -1
        },
        {
            "first": 67,
            "second": 121,
            "amount": -1
        },
        {
            "first": 68,
            "second": 42,
            "amount": -1
        },
        {
            "first": 68,
            "second": 47,
            "amount": -1
        },
        {
            "first": 68,
            "second": 65,
            "amount": -1
        },
        {
            "first": 68,
            "second": 74,
            "amount": -4
        },
        {
            "first": 68,
            "second": 84,
            "amount": -2
        },
        {
            "first": 68,
            "second": 86,
            "amount": -1
        },
        {
            "first": 68,
            "second": 88,
            "amount": -2
        },
        {
            "first": 68,
            "second": 89,
            "amount": -1
        },
        {
            "first": 68,
            "second": 90,
            "amount": -1
        },
        {
            "first": 68,
            "second": 120,
            "amount": -1
        },
        {
            "first": 68,
            "second": 122,
            "amount": -1
        },
        {
            "first": 69,
            "second": 99,
            "amount": -1
        },
        {
            "first": 69,
            "second": 100,
            "amount": -1
        },
        {
            "first": 69,
            "second": 101,
            "amount": -1
        },
        {
            "first": 69,
            "second": 111,
            "amount": -1
        },
        {
            "first": 69,
            "second": 113,
            "amount": -1
        },
        {
            "first": 69,
            "second": 116,
            "amount": -2
        },
        {
            "first": 69,
            "second": 118,
            "amount": -1
        },
        {
            "first": 69,
            "second": 120,
            "amount": -4
        },
        {
            "first": 69,
            "second": 121,
            "amount": -1
        },
        {
            "first": 70,
            "second": 44,
            "amount": -7
        },
        {
            "first": 70,
            "second": 46,
            "amount": -7
        },
        {
            "first": 70,
            "second": 47,
            "amount": -3
        },
        {
            "first": 70,
            "second": 65,
            "amount": -3
        },
        {
            "first": 70,
            "second": 67,
            "amount": -1
        },
        {
            "first": 70,
            "second": 71,
            "amount": -1
        },
        {
            "first": 70,
            "second": 74,
            "amount": -10
        },
        {
            "first": 70,
            "second": 79,
            "amount": -1
        },
        {
            "first": 70,
            "second": 81,
            "amount": -1
        },
        {
            "first": 70,
            "second": 83,
            "amount": -2
        },
        {
            "first": 70,
            "second": 86,
            "amount": -1
        },
        {
            "first": 70,
            "second": 87,
            "amount": -1
        },
        {
            "first": 70,
            "second": 88,
            "amount": -2
        },
        {
            "first": 70,
            "second": 89,
            "amount": -1
        },
        {
            "first": 70,
            "second": 90,
            "amount": -2
        },
        {
            "first": 70,
            "second": 97,
            "amount": -3
        },
        {
            "first": 70,
            "second": 99,
            "amount": -1
        },
        {
            "first": 70,
            "second": 100,
            "amount": -1
        },
        {
            "first": 70,
            "second": 101,
            "amount": -1
        },
        {
            "first": 70,
            "second": 103,
            "amount": -2
        },
        {
            "first": 70,
            "second": 109,
            "amount": -1
        },
        {
            "first": 70,
            "second": 110,
            "amount": -1
        },
        {
            "first": 70,
            "second": 111,
            "amount": -1
        },
        {
            "first": 70,
            "second": 112,
            "amount": -1
        },
        {
            "first": 70,
            "second": 113,
            "amount": -1
        },
        {
            "first": 70,
            "second": 114,
            "amount": -1
        },
        {
            "first": 70,
            "second": 115,
            "amount": -2
        },
        {
            "first": 70,
            "second": 116,
            "amount": -1
        },
        {
            "first": 70,
            "second": 117,
            "amount": -1
        },
        {
            "first": 70,
            "second": 118,
            "amount": -3
        },
        {
            "first": 70,
            "second": 119,
            "amount": -2
        },
        {
            "first": 70,
            "second": 120,
            "amount": -3
        },
        {
            "first": 70,
            "second": 121,
            "amount": -2
        },
        {
            "first": 70,
            "second": 122,
            "amount": -4
        },
        {
            "first": 71,
            "second": 42,
            "amount": -1
        },
        {
            "first": 71,
            "second": 65,
            "amount": -1
        },
        {
            "first": 71,
            "second": 84,
            "amount": -1
        },
        {
            "first": 71,
            "second": 86,
            "amount": -1
        },
        {
            "first": 71,
            "second": 87,
            "amount": -1
        },
        {
            "first": 74,
            "second": 44,
            "amount": -2
        },
        {
            "first": 74,
            "second": 46,
            "amount": -2
        },
        {
            "first": 74,
            "second": 74,
            "amount": -3
        },
        {
            "first": 75,
            "second": 34,
            "amount": -3
        },
        {
            "first": 75,
            "second": 39,
            "amount": -3
        },
        {
            "first": 75,
            "second": 42,
            "amount": -3
        },
        {
            "first": 75,
            "second": 45,
            "amount": -4
        },
        {
            "first": 75,
            "second": 63,
            "amount": -1
        },
        {
            "first": 75,
            "second": 65,
            "amount": -1
        },
        {
            "first": 75,
            "second": 67,
            "amount": -2
        },
        {
            "first": 75,
            "second": 71,
            "amount": -2
        },
        {
            "first": 75,
            "second": 79,
            "amount": -2
        },
        {
            "first": 75,
            "second": 81,
            "amount": -2
        },
        {
            "first": 75,
            "second": 83,
            "amount": -2
        },
        {
            "first": 75,
            "second": 84,
            "amount": -2
        },
        {
            "first": 75,
            "second": 85,
            "amount": -1
        },
        {
            "first": 75,
            "second": 86,
            "amount": -1
        },
        {
            "first": 75,
            "second": 87,
            "amount": -1
        },
        {
            "first": 75,
            "second": 89,
            "amount": -2
        },
        {
            "first": 75,
            "second": 99,
            "amount": -1
        },
        {
            "first": 75,
            "second": 100,
            "amount": -1
        },
        {
            "first": 75,
            "second": 101,
            "amount": -1
        },
        {
            "first": 75,
            "second": 106,
            "amount": -1
        },
        {
            "first": 75,
            "second": 111,
            "amount": -1
        },
        {
            "first": 75,
            "second": 113,
            "amount": -1
        },
        {
            "first": 75,
            "second": 116,
            "amount": -4
        },
        {
            "first": 75,
            "second": 117,
            "amount": -1
        },
        {
            "first": 75,
            "second": 118,
            "amount": -3
        },
        {
            "first": 75,
            "second": 119,
            "amount": -2
        },
        {
            "first": 75,
            "second": 120,
            "amount": -2
        },
        {
            "first": 75,
            "second": 121,
            "amount": -3
        },
        {
            "first": 75,
            "second": 122,
            "amount": -1
        },
        {
            "first": 76,
            "second": 34,
            "amount": -12
        },
        {
            "first": 76,
            "second": 39,
            "amount": -12
        },
        {
            "first": 76,
            "second": 42,
            "amount": -13
        },
        {
            "first": 76,
            "second": 45,
            "amount": -3
        },
        {
            "first": 76,
            "second": 63,
            "amount": -4
        },
        {
            "first": 76,
            "second": 65,
            "amount": -1
        },
        {
            "first": 76,
            "second": 67,
            "amount": -3
        },
        {
            "first": 76,
            "second": 71,
            "amount": -3
        },
        {
            "first": 76,
            "second": 79,
            "amount": -3
        },
        {
            "first": 76,
            "second": 81,
            "amount": -3
        },
        {
            "first": 76,
            "second": 83,
            "amount": -2
        },
        {
            "first": 76,
            "second": 84,
            "amount": -9
        },
        {
            "first": 76,
            "second": 85,
            "amount": -3
        },
        {
            "first": 76,
            "second": 86,
            "amount": -6
        },
        {
            "first": 76,
            "second": 87,
            "amount": -4
        },
        {
            "first": 76,
            "second": 89,
            "amount": -6
        },
        {
            "first": 76,
            "second": 92,
            "amount": -6
        },
        {
            "first": 76,
            "second": 99,
            "amount": -1
        },
        {
            "first": 76,
            "second": 100,
            "amount": -1
        },
        {
            "first": 76,
            "second": 101,
            "amount": -1
        },
        {
            "first": 76,
            "second": 102,
            "amount": -1
        },
        {
            "first": 76,
            "second": 103,
            "amount": -1
        },
        {
            "first": 76,
            "second": 111,
            "amount": -1
        },
        {
            "first": 76,
            "second": 113,
            "amount": -1
        },
        {
            "first": 76,
            "second": 116,
            "amount": -2
        },
        {
            "first": 76,
            "second": 117,
            "amount": -1
        },
        {
            "first": 76,
            "second": 118,
            "amount": -4
        },
        {
            "first": 76,
            "second": 119,
            "amount": -3
        },
        {
            "first": 76,
            "second": 121,
            "amount": -4
        },
        {
            "first": 79,
            "second": 42,
            "amount": -1
        },
        {
            "first": 79,
            "second": 47,
            "amount": -1
        },
        {
            "first": 79,
            "second": 65,
            "amount": -1
        },
        {
            "first": 79,
            "second": 74,
            "amount": -4
        },
        {
            "first": 79,
            "second": 84,
            "amount": -2
        },
        {
            "first": 79,
            "second": 86,
            "amount": -1
        },
        {
            "first": 79,
            "second": 88,
            "amount": -2
        },
        {
            "first": 79,
            "second": 89,
            "amount": -1
        },
        {
            "first": 79,
            "second": 90,
            "amount": -1
        },
        {
            "first": 79,
            "second": 120,
            "amount": -1
        },
        {
            "first": 79,
            "second": 122,
            "amount": -1
        },
        {
            "first": 80,
            "second": 44,
            "amount": -10
        },
        {
            "first": 80,
            "second": 45,
            "amount": -1
        },
        {
            "first": 80,
            "second": 46,
            "amount": -10
        },
        {
            "first": 80,
            "second": 47,
            "amount": -3
        },
        {
            "first": 80,
            "second": 65,
            "amount": -4
        },
        {
            "first": 80,
            "second": 74,
            "amount": -10
        },
        {
            "first": 80,
            "second": 83,
            "amount": -1
        },
        {
            "first": 80,
            "second": 84,
            "amount": -1
        },
        {
            "first": 80,
            "second": 88,
            "amount": -2
        },
        {
            "first": 80,
            "second": 89,
            "amount": -1
        },
        {
            "first": 80,
            "second": 90,
            "amount": -3
        },
        {
            "first": 80,
            "second": 97,
            "amount": -2
        },
        {
            "first": 80,
            "second": 99,
            "amount": -1
        },
        {
            "first": 80,
            "second": 100,
            "amount": -1
        },
        {
            "first": 80,
            "second": 101,
            "amount": -1
        },
        {
            "first": 80,
            "second": 103,
            "amount": -1
        },
        {
            "first": 80,
            "second": 111,
            "amount": -1
        },
        {
            "first": 80,
            "second": 113,
            "amount": -1
        },
        {
            "first": 80,
            "second": 115,
            "amount": -1
        },
        {
            "first": 80,
            "second": 120,
            "amount": -1
        },
        {
            "first": 80,
            "second": 122,
            "amount": -1
        },
        {
            "first": 81,
            "second": 42,
            "amount": -1
        },
        {
            "first": 81,
            "second": 47,
            "amount": -1
        },
        {
            "first": 81,
            "second": 65,
            "amount": -1
        },
        {
            "first": 81,
            "second": 74,
            "amount": -4
        },
        {
            "first": 81,
            "second": 84,
            "amount": -2
        },
        {
            "first": 81,
            "second": 86,
            "amount": -1
        },
        {
            "first": 81,
            "second": 88,
            "amount": -2
        },
        {
            "first": 81,
            "second": 89,
            "amount": -1
        },
        {
            "first": 81,
            "second": 90,
            "amount": -1
        },
        {
            "first": 81,
            "second": 120,
            "amount": -1
        },
        {
            "first": 81,
            "second": 122,
            "amount": -1
        },
        {
            "first": 82,
            "second": 42,
            "amount": -1
        },
        {
            "first": 82,
            "second": 45,
            "amount": -2
        },
        {
            "first": 82,
            "second": 65,
            "amount": -1
        },
        {
            "first": 82,
            "second": 74,
            "amount": -1
        },
        {
            "first": 82,
            "second": 83,
            "amount": -1
        },
        {
            "first": 82,
            "second": 84,
            "amount": -1
        },
        {
            "first": 82,
            "second": 86,
            "amount": -1
        },
        {
            "first": 82,
            "second": 88,
            "amount": -1
        },
        {
            "first": 82,
            "second": 89,
            "amount": -1
        },
        {
            "first": 82,
            "second": 90,
            "amount": -1
        },
        {
            "first": 82,
            "second": 97,
            "amount": -1
        },
        {
            "first": 82,
            "second": 99,
            "amount": -1
        },
        {
            "first": 82,
            "second": 100,
            "amount": -1
        },
        {
            "first": 82,
            "second": 101,
            "amount": -1
        },
        {
            "first": 82,
            "second": 103,
            "amount": -1
        },
        {
            "first": 82,
            "second": 111,
            "amount": -1
        },
        {
            "first": 82,
            "second": 113,
            "amount": -1
        },
        {
            "first": 82,
            "second": 120,
            "amount": -1
        },
        {
            "first": 82,
            "second": 122,
            "amount": -1
        },
        {
            "first": 83,
            "second": 42,
            "amount": -1
        },
        {
            "first": 83,
            "second": 45,
            "amount": 1
        },
        {
            "first": 83,
            "second": 74,
            "amount": -1
        },
        {
            "first": 83,
            "second": 83,
            "amount": -1
        },
        {
            "first": 83,
            "second": 84,
            "amount": -1
        },
        {
            "first": 83,
            "second": 89,
            "amount": -1
        },
        {
            "first": 83,
            "second": 116,
            "amount": -2
        },
        {
            "first": 83,
            "second": 122,
            "amount": -1
        },
        {
            "first": 84,
            "second": 44,
            "amount": -9
        },
        {
            "first": 84,
            "second": 45,
            "amount": -4
        },
        {
            "first": 84,
            "second": 46,
            "amount": -9
        },
        {
            "first": 84,
            "second": 47,
            "amount": -4
        },
        {
            "first": 84,
            "second": 58,
            "amount": -1
        },
        {
            "first": 84,
            "second": 59,
            "amount": -1
        },
        {
            "first": 84,
            "second": 65,
            "amount": -3
        },
        {
            "first": 84,
            "second": 67,
            "amount": -2
        },
        {
            "first": 84,
            "second": 71,
            "amount": -2
        },
        {
            "first": 84,
            "second": 74,
            "amount": -9
        },
        {
            "first": 84,
            "second": 79,
            "amount": -2
        },
        {
            "first": 84,
            "second": 81,
            "amount": -2
        },
        {
            "first": 84,
            "second": 83,
            "amount": -1
        },
        {
            "first": 84,
            "second": 88,
            "amount": -1
        },
        {
            "first": 84,
            "second": 89,
            "amount": -1
        },
        {
            "first": 84,
            "second": 90,
            "amount": -3
        },
        {
            "first": 84,
            "second": 97,
            "amount": -4
        },
        {
            "first": 84,
            "second": 99,
            "amount": -6
        },
        {
            "first": 84,
            "second": 100,
            "amount": -6
        },
        {
            "first": 84,
            "second": 101,
            "amount": -6
        },
        {
            "first": 84,
            "second": 102,
            "amount": -1
        },
        {
            "first": 84,
            "second": 103,
            "amount": -4
        },
        {
            "first": 84,
            "second": 109,
            "amount": -3
        },
        {
            "first": 84,
            "second": 110,
            "amount": -3
        },
        {
            "first": 84,
            "second": 111,
            "amount": -6
        },
        {
            "first": 84,
            "second": 112,
            "amount": -3
        },
        {
            "first": 84,
            "second": 113,
            "amount": -6
        },
        {
            "first": 84,
            "second": 114,
            "amount": -3
        },
        {
            "first": 84,
            "second": 115,
            "amount": -4
        },
        {
            "first": 84,
            "second": 116,
            "amount": -1
        },
        {
            "first": 84,
            "second": 117,
            "amount": -3
        },
        {
            "first": 84,
            "second": 118,
            "amount": -1
        },
        {
            "first": 84,
            "second": 119,
            "amount": -1
        },
        {
            "first": 84,
            "second": 120,
            "amount": -1
        },
        {
            "first": 84,
            "second": 121,
            "amount": -1
        },
        {
            "first": 84,
            "second": 122,
            "amount": -4
        },
        {
            "first": 85,
            "second": 44,
            "amount": -2
        },
        {
            "first": 85,
            "second": 46,
            "amount": -2
        },
        {
            "first": 85,
            "second": 47,
            "amount": -1
        },
        {
            "first": 85,
            "second": 65,
            "amount": -1
        },
        {
            "first": 85,
            "second": 74,
            "amount": -4
        },
        {
            "first": 85,
            "second": 83,
            "amount": -1
        },
        {
            "first": 85,
            "second": 86,
            "amount": -1
        },
        {
            "first": 85,
            "second": 88,
            "amount": -1
        },
        {
            "first": 85,
            "second": 89,
            "amount": -1
        },
        {
            "first": 85,
            "second": 97,
            "amount": -1
        },
        {
            "first": 85,
            "second": 103,
            "amount": -1
        },
        {
            "first": 85,
            "second": 115,
            "amount": -1
        },
        {
            "first": 85,
            "second": 120,
            "amount": -1
        },
        {
            "first": 86,
            "second": 44,
            "amount": -6
        },
        {
            "first": 86,
            "second": 45,
            "amount": -1
        },
        {
            "first": 86,
            "second": 46,
            "amount": -6
        },
        {
            "first": 86,
            "second": 47,
            "amount": -1
        },
        {
            "first": 86,
            "second": 65,
            "amount": -1
        },
        {
            "first": 86,
            "second": 67,
            "amount": -1
        },
        {
            "first": 86,
            "second": 71,
            "amount": -1
        },
        {
            "first": 86,
            "second": 74,
            "amount": -4
        },
        {
            "first": 86,
            "second": 79,
            "amount": -1
        },
        {
            "first": 86,
            "second": 81,
            "amount": -1
        },
        {
            "first": 86,
            "second": 83,
            "amount": -1
        },
        {
            "first": 86,
            "second": 85,
            "amount": -1
        },
        {
            "first": 86,
            "second": 86,
            "amount": 1
        },
        {
            "first": 86,
            "second": 90,
            "amount": -1
        },
        {
            "first": 86,
            "second": 97,
            "amount": -3
        },
        {
            "first": 86,
            "second": 99,
            "amount": -1
        },
        {
            "first": 86,
            "second": 100,
            "amount": -1
        },
        {
            "first": 86,
            "second": 101,
            "amount": -1
        },
        {
            "first": 86,
            "second": 103,
            "amount": -1
        },
        {
            "first": 86,
            "second": 109,
            "amount": -1
        },
        {
            "first": 86,
            "second": 110,
            "amount": -1
        },
        {
            "first": 86,
            "second": 111,
            "amount": -1
        },
        {
            "first": 86,
            "second": 112,
            "amount": -1
        },
        {
            "first": 86,
            "second": 113,
            "amount": -1
        },
        {
            "first": 86,
            "second": 114,
            "amount": -1
        },
        {
            "first": 86,
            "second": 115,
            "amount": -1
        },
        {
            "first": 86,
            "second": 117,
            "amount": -2
        },
        {
            "first": 86,
            "second": 118,
            "amount": -1
        },
        {
            "first": 86,
            "second": 119,
            "amount": -1
        },
        {
            "first": 86,
            "second": 120,
            "amount": -1
        },
        {
            "first": 86,
            "second": 121,
            "amount": -1
        },
        {
            "first": 86,
            "second": 122,
            "amount": -1
        },
        {
            "first": 87,
            "second": 44,
            "amount": -3
        },
        {
            "first": 87,
            "second": 45,
            "amount": -1
        },
        {
            "first": 87,
            "second": 46,
            "amount": -3
        },
        {
            "first": 87,
            "second": 74,
            "amount": -3
        },
        {
            "first": 87,
            "second": 97,
            "amount": -1
        },
        {
            "first": 87,
            "second": 122,
            "amount": -1
        },
        {
            "first": 88,
            "second": 34,
            "amount": -1
        },
        {
            "first": 88,
            "second": 39,
            "amount": -1
        },
        {
            "first": 88,
            "second": 42,
            "amount": -1
        },
        {
            "first": 88,
            "second": 45,
            "amount": -3
        },
        {
            "first": 88,
            "second": 65,
            "amount": -1
        },
        {
            "first": 88,
            "second": 67,
            "amount": -2
        },
        {
            "first": 88,
            "second": 71,
            "amount": -2
        },
        {
            "first": 88,
            "second": 74,
            "amount": -1
        },
        {
            "first": 88,
            "second": 79,
            "amount": -2
        },
        {
            "first": 88,
            "second": 81,
            "amount": -2
        },
        {
            "first": 88,
            "second": 83,
            "amount": -1
        },
        {
            "first": 88,
            "second": 84,
            "amount": -1
        },
        {
            "first": 88,
            "second": 85,
            "amount": -1
        },
        {
            "first": 88,
            "second": 97,
            "amount": -1
        },
        {
            "first": 88,
            "second": 99,
            "amount": -1
        },
        {
            "first": 88,
            "second": 100,
            "amount": -1
        },
        {
            "first": 88,
            "second": 101,
            "amount": -1
        },
        {
            "first": 88,
            "second": 102,
            "amount": -2
        },
        {
            "first": 88,
            "second": 111,
            "amount": -1
        },
        {
            "first": 88,
            "second": 113,
            "amount": -1
        },
        {
            "first": 88,
            "second": 116,
            "amount": -2
        },
        {
            "first": 88,
            "second": 117,
            "amount": -1
        },
        {
            "first": 88,
            "second": 118,
            "amount": -2
        },
        {
            "first": 88,
            "second": 119,
            "amount": -1
        },
        {
            "first": 88,
            "second": 120,
            "amount": -1
        },
        {
            "first": 88,
            "second": 121,
            "amount": -2
        },
        {
            "first": 88,
            "second": 122,
            "amount": -1
        },
        {
            "first": 89,
            "second": 44,
            "amount": -9
        },
        {
            "first": 89,
            "second": 45,
            "amount": -6
        },
        {
            "first": 89,
            "second": 46,
            "amount": -9
        },
        {
            "first": 89,
            "second": 47,
            "amount": -3
        },
        {
            "first": 89,
            "second": 58,
            "amount": -3
        },
        {
            "first": 89,
            "second": 59,
            "amount": -3
        },
        {
            "first": 89,
            "second": 63,
            "amount": -1
        },
        {
            "first": 89,
            "second": 65,
            "amount": -1
        },
        {
            "first": 89,
            "second": 67,
            "amount": -1
        },
        {
            "first": 89,
            "second": 71,
            "amount": -1
        },
        {
            "first": 89,
            "second": 74,
            "amount": -7
        },
        {
            "first": 89,
            "second": 79,
            "amount": -1
        },
        {
            "first": 89,
            "second": 81,
            "amount": -1
        },
        {
            "first": 89,
            "second": 83,
            "amount": -1
        },
        {
            "first": 89,
            "second": 84,
            "amount": -1
        },
        {
            "first": 89,
            "second": 85,
            "amount": -1
        },
        {
            "first": 89,
            "second": 90,
            "amount": -1
        },
        {
            "first": 89,
            "second": 97,
            "amount": -6
        },
        {
            "first": 89,
            "second": 99,
            "amount": -4
        },
        {
            "first": 89,
            "second": 100,
            "amount": -4
        },
        {
            "first": 89,
            "second": 101,
            "amount": -4
        },
        {
            "first": 89,
            "second": 103,
            "amount": -4
        },
        {
            "first": 89,
            "second": 109,
            "amount": -3
        },
        {
            "first": 89,
            "second": 110,
            "amount": -3
        },
        {
            "first": 89,
            "second": 111,
            "amount": -4
        },
        {
            "first": 89,
            "second": 112,
            "amount": -3
        },
        {
            "first": 89,
            "second": 113,
            "amount": -4
        },
        {
            "first": 89,
            "second": 114,
            "amount": -3
        },
        {
            "first": 89,
            "second": 115,
            "amount": -4
        },
        {
            "first": 89,
            "second": 116,
            "amount": -4
        },
        {
            "first": 89,
            "second": 117,
            "amount": -3
        },
        {
            "first": 89,
            "second": 118,
            "amount": -1
        },
        {
            "first": 89,
            "second": 119,
            "amount": -2
        },
        {
            "first": 89,
            "second": 120,
            "amount": -3
        },
        {
            "first": 89,
            "second": 121,
            "amount": -1
        },
        {
            "first": 89,
            "second": 122,
            "amount": -4
        },
        {
            "first": 90,
            "second": 45,
            "amount": -1
        },
        {
            "first": 90,
            "second": 67,
            "amount": -1
        },
        {
            "first": 90,
            "second": 71,
            "amount": -1
        },
        {
            "first": 90,
            "second": 74,
            "amount": -1
        },
        {
            "first": 90,
            "second": 79,
            "amount": -1
        },
        {
            "first": 90,
            "second": 81,
            "amount": -1
        },
        {
            "first": 90,
            "second": 83,
            "amount": -2
        },
        {
            "first": 90,
            "second": 85,
            "amount": -1
        },
        {
            "first": 90,
            "second": 89,
            "amount": -1
        },
        {
            "first": 90,
            "second": 90,
            "amount": -1
        },
        {
            "first": 90,
            "second": 97,
            "amount": -1
        },
        {
            "first": 90,
            "second": 99,
            "amount": -1
        },
        {
            "first": 90,
            "second": 100,
            "amount": -1
        },
        {
            "first": 90,
            "second": 101,
            "amount": -1
        },
        {
            "first": 90,
            "second": 102,
            "amount": -1
        },
        {
            "first": 90,
            "second": 103,
            "amount": -1
        },
        {
            "first": 90,
            "second": 111,
            "amount": -1
        },
        {
            "first": 90,
            "second": 113,
            "amount": -1
        },
        {
            "first": 90,
            "second": 116,
            "amount": -1
        },
        {
            "first": 90,
            "second": 117,
            "amount": -1
        },
        {
            "first": 90,
            "second": 118,
            "amount": -1
        },
        {
            "first": 90,
            "second": 119,
            "amount": -1
        },
        {
            "first": 90,
            "second": 120,
            "amount": -2
        },
        {
            "first": 90,
            "second": 121,
            "amount": -1
        },
        {
            "first": 91,
            "second": 74,
            "amount": -1
        },
        {
            "first": 91,
            "second": 106,
            "amount": 6
        },
        {
            "first": 92,
            "second": 84,
            "amount": -4
        },
        {
            "first": 92,
            "second": 85,
            "amount": -1
        },
        {
            "first": 92,
            "second": 86,
            "amount": -3
        },
        {
            "first": 92,
            "second": 87,
            "amount": -1
        },
        {
            "first": 92,
            "second": 89,
            "amount": -4
        },
        {
            "first": 92,
            "second": 103,
            "amount": 1
        },
        {
            "first": 92,
            "second": 106,
            "amount": 4
        },
        {
            "first": 92,
            "second": 118,
            "amount": -1
        },
        {
            "first": 92,
            "second": 119,
            "amount": -1
        },
        {
            "first": 97,
            "second": 42,
            "amount": -3
        },
        {
            "first": 97,
            "second": 63,
            "amount": -3
        },
        {
            "first": 97,
            "second": 84,
            "amount": -2
        },
        {
            "first": 97,
            "second": 86,
            "amount": -2
        },
        {
            "first": 97,
            "second": 87,
            "amount": -1
        },
        {
            "first": 97,
            "second": 89,
            "amount": -2
        },
        {
            "first": 97,
            "second": 116,
            "amount": -1
        },
        {
            "first": 98,
            "second": 34,
            "amount": -1
        },
        {
            "first": 98,
            "second": 39,
            "amount": -1
        },
        {
            "first": 98,
            "second": 44,
            "amount": -1
        },
        {
            "first": 98,
            "second": 46,
            "amount": -1
        },
        {
            "first": 98,
            "second": 63,
            "amount": -3
        },
        {
            "first": 98,
            "second": 84,
            "amount": -4
        },
        {
            "first": 98,
            "second": 86,
            "amount": -1
        },
        {
            "first": 98,
            "second": 88,
            "amount": -1
        },
        {
            "first": 98,
            "second": 89,
            "amount": -4
        },
        {
            "first": 98,
            "second": 92,
            "amount": -3
        },
        {
            "first": 98,
            "second": 97,
            "amount": -1
        },
        {
            "first": 98,
            "second": 102,
            "amount": 0
        },
        {
            "first": 98,
            "second": 115,
            "amount": 0
        },
        {
            "first": 98,
            "second": 116,
            "amount": -2
        },
        {
            "first": 98,
            "second": 118,
            "amount": -1
        },
        {
            "first": 98,
            "second": 119,
            "amount": -1
        },
        {
            "first": 98,
            "second": 120,
            "amount": -2
        },
        {
            "first": 98,
            "second": 121,
            "amount": -1
        },
        {
            "first": 98,
            "second": 122,
            "amount": -1
        },
        {
            "first": 99,
            "second": 45,
            "amount": -3
        },
        {
            "first": 99,
            "second": 84,
            "amount": -1
        },
        {
            "first": 99,
            "second": 86,
            "amount": -1
        },
        {
            "first": 99,
            "second": 89,
            "amount": -2
        },
        {
            "first": 99,
            "second": 97,
            "amount": -1
        },
        {
            "first": 99,
            "second": 99,
            "amount": -2
        },
        {
            "first": 99,
            "second": 100,
            "amount": -2
        },
        {
            "first": 99,
            "second": 101,
            "amount": -2
        },
        {
            "first": 99,
            "second": 103,
            "amount": -1
        },
        {
            "first": 99,
            "second": 111,
            "amount": -2
        },
        {
            "first": 99,
            "second": 113,
            "amount": -2
        },
        {
            "first": 99,
            "second": 116,
            "amount": -1
        },
        {
            "first": 99,
            "second": 120,
            "amount": -1
        },
        {
            "first": 101,
            "second": 42,
            "amount": -1
        },
        {
            "first": 101,
            "second": 45,
            "amount": 1
        },
        {
            "first": 101,
            "second": 63,
            "amount": -1
        },
        {
            "first": 101,
            "second": 74,
            "amount": -1
        },
        {
            "first": 101,
            "second": 83,
            "amount": -1
        },
        {
            "first": 101,
            "second": 84,
            "amount": -2
        },
        {
            "first": 101,
            "second": 86,
            "amount": -2
        },
        {
            "first": 101,
            "second": 87,
            "amount": -1
        },
        {
            "first": 101,
            "second": 89,
            "amount": -3
        },
        {
            "first": 101,
            "second": 92,
            "amount": -3
        },
        {
            "first": 101,
            "second": 97,
            "amount": -1
        },
        {
            "first": 101,
            "second": 102,
            "amount": 0
        },
        {
            "first": 101,
            "second": 103,
            "amount": -1
        },
        {
            "first": 101,
            "second": 116,
            "amount": -1
        },
        {
            "first": 101,
            "second": 118,
            "amount": -1
        },
        {
            "first": 101,
            "second": 119,
            "amount": -1
        },
        {
            "first": 101,
            "second": 120,
            "amount": -1
        },
        {
            "first": 101,
            "second": 121,
            "amount": -1
        },
        {
            "first": 101,
            "second": 122,
            "amount": -1
        },
        {
            "first": 102,
            "second": 34,
            "amount": 3
        },
        {
            "first": 102,
            "second": 39,
            "amount": 3
        },
        {
            "first": 102,
            "second": 41,
            "amount": 2
        },
        {
            "first": 102,
            "second": 44,
            "amount": -5
        },
        {
            "first": 102,
            "second": 45,
            "amount": -1
        },
        {
            "first": 102,
            "second": 46,
            "amount": -5
        },
        {
            "first": 102,
            "second": 63,
            "amount": 1
        },
        {
            "first": 102,
            "second": 84,
            "amount": 3
        },
        {
            "first": 102,
            "second": 86,
            "amount": 4
        },
        {
            "first": 102,
            "second": 87,
            "amount": 3
        },
        {
            "first": 102,
            "second": 88,
            "amount": 1
        },
        {
            "first": 102,
            "second": 89,
            "amount": 3
        },
        {
            "first": 102,
            "second": 92,
            "amount": 1
        },
        {
            "first": 102,
            "second": 93,
            "amount": 2
        },
        {
            "first": 102,
            "second": 97,
            "amount": -1
        },
        {
            "first": 102,
            "second": 99,
            "amount": -1
        },
        {
            "first": 102,
            "second": 100,
            "amount": -1
        },
        {
            "first": 102,
            "second": 101,
            "amount": -1
        },
        {
            "first": 102,
            "second": 103,
            "amount": -1
        },
        {
            "first": 102,
            "second": 106,
            "amount": -1
        },
        {
            "first": 102,
            "second": 111,
            "amount": -1
        },
        {
            "first": 102,
            "second": 113,
            "amount": -1
        },
        {
            "first": 102,
            "second": 115,
            "amount": -1
        },
        {
            "first": 102,
            "second": 120,
            "amount": -1
        },
        {
            "first": 102,
            "second": 122,
            "amount": -1
        },
        {
            "first": 102,
            "second": 125,
            "amount": 2
        },
        {
            "first": 103,
            "second": 42,
            "amount": -1
        },
        {
            "first": 103,
            "second": 47,
            "amount": 1
        },
        {
            "first": 103,
            "second": 63,
            "amount": -4
        },
        {
            "first": 103,
            "second": 84,
            "amount": -3
        },
        {
            "first": 103,
            "second": 89,
            "amount": -1
        },
        {
            "first": 103,
            "second": 97,
            "amount": -1
        },
        {
            "first": 103,
            "second": 99,
            "amount": -1
        },
        {
            "first": 103,
            "second": 100,
            "amount": -1
        },
        {
            "first": 103,
            "second": 101,
            "amount": -1
        },
        {
            "first": 103,
            "second": 106,
            "amount": 4
        },
        {
            "first": 103,
            "second": 111,
            "amount": -1
        },
        {
            "first": 103,
            "second": 113,
            "amount": -1
        },
        {
            "first": 103,
            "second": 118,
            "amount": -1
        },
        {
            "first": 103,
            "second": 119,
            "amount": -1
        },
        {
            "first": 103,
            "second": 122,
            "amount": -1
        },
        {
            "first": 104,
            "second": 42,
            "amount": -1
        },
        {
            "first": 104,
            "second": 63,
            "amount": -1
        },
        {
            "first": 104,
            "second": 84,
            "amount": -2
        },
        {
            "first": 104,
            "second": 86,
            "amount": -1
        },
        {
            "first": 104,
            "second": 89,
            "amount": -2
        },
        {
            "first": 107,
            "second": 42,
            "amount": -1
        },
        {
            "first": 107,
            "second": 45,
            "amount": -7
        },
        {
            "first": 107,
            "second": 63,
            "amount": -3
        },
        {
            "first": 107,
            "second": 84,
            "amount": -4
        },
        {
            "first": 107,
            "second": 89,
            "amount": -1
        },
        {
            "first": 107,
            "second": 97,
            "amount": -1
        },
        {
            "first": 107,
            "second": 99,
            "amount": -2
        },
        {
            "first": 107,
            "second": 100,
            "amount": -2
        },
        {
            "first": 107,
            "second": 101,
            "amount": -2
        },
        {
            "first": 107,
            "second": 103,
            "amount": -1
        },
        {
            "first": 107,
            "second": 106,
            "amount": -1
        },
        {
            "first": 107,
            "second": 111,
            "amount": -2
        },
        {
            "first": 107,
            "second": 113,
            "amount": -2
        },
        {
            "first": 107,
            "second": 116,
            "amount": -3
        },
        {
            "first": 107,
            "second": 117,
            "amount": -1
        },
        {
            "first": 107,
            "second": 120,
            "amount": -1
        },
        {
            "first": 107,
            "second": 122,
            "amount": -1
        },
        {
            "first": 108,
            "second": 106,
            "amount": 1
        },
        {
            "first": 109,
            "second": 42,
            "amount": -1
        },
        {
            "first": 109,
            "second": 63,
            "amount": -1
        },
        {
            "first": 109,
            "second": 84,
            "amount": -2
        },
        {
            "first": 109,
            "second": 86,
            "amount": -1
        },
        {
            "first": 109,
            "second": 89,
            "amount": -2
        },
        {
            "first": 110,
            "second": 42,
            "amount": -1
        },
        {
            "first": 110,
            "second": 63,
            "amount": -1
        },
        {
            "first": 110,
            "second": 84,
            "amount": -2
        },
        {
            "first": 110,
            "second": 86,
            "amount": -1
        },
        {
            "first": 110,
            "second": 89,
            "amount": -2
        },
        {
            "first": 111,
            "second": 34,
            "amount": -1
        },
        {
            "first": 111,
            "second": 39,
            "amount": -1
        },
        {
            "first": 111,
            "second": 44,
            "amount": -1
        },
        {
            "first": 111,
            "second": 46,
            "amount": -1
        },
        {
            "first": 111,
            "second": 63,
            "amount": -3
        },
        {
            "first": 111,
            "second": 84,
            "amount": -4
        },
        {
            "first": 111,
            "second": 86,
            "amount": -1
        },
        {
            "first": 111,
            "second": 88,
            "amount": -1
        },
        {
            "first": 111,
            "second": 89,
            "amount": -4
        },
        {
            "first": 111,
            "second": 92,
            "amount": -3
        },
        {
            "first": 111,
            "second": 97,
            "amount": -1
        },
        {
            "first": 111,
            "second": 102,
            "amount": 0
        },
        {
            "first": 111,
            "second": 115,
            "amount": 0
        },
        {
            "first": 111,
            "second": 116,
            "amount": -2
        },
        {
            "first": 111,
            "second": 118,
            "amount": -1
        },
        {
            "first": 111,
            "second": 119,
            "amount": -1
        },
        {
            "first": 111,
            "second": 120,
            "amount": -2
        },
        {
            "first": 111,
            "second": 121,
            "amount": -1
        },
        {
            "first": 111,
            "second": 122,
            "amount": -1
        },
        {
            "first": 112,
            "second": 34,
            "amount": -1
        },
        {
            "first": 112,
            "second": 39,
            "amount": -1
        },
        {
            "first": 112,
            "second": 44,
            "amount": -1
        },
        {
            "first": 112,
            "second": 46,
            "amount": -1
        },
        {
            "first": 112,
            "second": 63,
            "amount": -3
        },
        {
            "first": 112,
            "second": 84,
            "amount": -4
        },
        {
            "first": 112,
            "second": 86,
            "amount": -1
        },
        {
            "first": 112,
            "second": 88,
            "amount": -1
        },
        {
            "first": 112,
            "second": 89,
            "amount": -4
        },
        {
            "first": 112,
            "second": 92,
            "amount": -3
        },
        {
            "first": 112,
            "second": 97,
            "amount": -1
        },
        {
            "first": 112,
            "second": 102,
            "amount": 0
        },
        {
            "first": 112,
            "second": 115,
            "amount": 0
        },
        {
            "first": 112,
            "second": 116,
            "amount": -2
        },
        {
            "first": 112,
            "second": 118,
            "amount": -1
        },
        {
            "first": 112,
            "second": 119,
            "amount": -1
        },
        {
            "first": 112,
            "second": 120,
            "amount": -2
        },
        {
            "first": 112,
            "second": 121,
            "amount": -1
        },
        {
            "first": 112,
            "second": 122,
            "amount": -1
        },
        {
            "first": 113,
            "second": 42,
            "amount": -1
        },
        {
            "first": 113,
            "second": 84,
            "amount": -1
        },
        {
            "first": 113,
            "second": 86,
            "amount": -1
        },
        {
            "first": 113,
            "second": 89,
            "amount": -2
        },
        {
            "first": 114,
            "second": 44,
            "amount": -7
        },
        {
            "first": 114,
            "second": 45,
            "amount": -2
        },
        {
            "first": 114,
            "second": 46,
            "amount": -7
        },
        {
            "first": 114,
            "second": 47,
            "amount": -1
        },
        {
            "first": 114,
            "second": 65,
            "amount": -1
        },
        {
            "first": 114,
            "second": 74,
            "amount": -6
        },
        {
            "first": 114,
            "second": 90,
            "amount": -1
        },
        {
            "first": 114,
            "second": 97,
            "amount": -2
        },
        {
            "first": 114,
            "second": 99,
            "amount": -1
        },
        {
            "first": 114,
            "second": 100,
            "amount": -1
        },
        {
            "first": 114,
            "second": 101,
            "amount": -1
        },
        {
            "first": 114,
            "second": 103,
            "amount": -1
        },
        {
            "first": 114,
            "second": 111,
            "amount": -1
        },
        {
            "first": 114,
            "second": 113,
            "amount": -1
        },
        {
            "first": 114,
            "second": 115,
            "amount": -1
        },
        {
            "first": 114,
            "second": 122,
            "amount": -1
        },
        {
            "first": 115,
            "second": 42,
            "amount": -1
        },
        {
            "first": 115,
            "second": 45,
            "amount": 1
        },
        {
            "first": 115,
            "second": 63,
            "amount": -2
        },
        {
            "first": 115,
            "second": 84,
            "amount": -2
        },
        {
            "first": 115,
            "second": 86,
            "amount": -1
        },
        {
            "first": 115,
            "second": 89,
            "amount": -1
        },
        {
            "first": 115,
            "second": 116,
            "amount": -1
        },
        {
            "first": 116,
            "second": 44,
            "amount": -1
        },
        {
            "first": 116,
            "second": 45,
            "amount": -3
        },
        {
            "first": 116,
            "second": 46,
            "amount": -1
        },
        {
            "first": 116,
            "second": 47,
            "amount": -1
        },
        {
            "first": 116,
            "second": 63,
            "amount": -3
        },
        {
            "first": 116,
            "second": 84,
            "amount": -1
        },
        {
            "first": 116,
            "second": 89,
            "amount": -1
        },
        {
            "first": 116,
            "second": 97,
            "amount": -1
        },
        {
            "first": 116,
            "second": 99,
            "amount": -1
        },
        {
            "first": 116,
            "second": 100,
            "amount": -1
        },
        {
            "first": 116,
            "second": 101,
            "amount": -1
        },
        {
            "first": 116,
            "second": 103,
            "amount": -1
        },
        {
            "first": 116,
            "second": 111,
            "amount": -1
        },
        {
            "first": 116,
            "second": 113,
            "amount": -1
        },
        {
            "first": 116,
            "second": 115,
            "amount": -1
        },
        {
            "first": 116,
            "second": 116,
            "amount": -1
        },
        {
            "first": 116,
            "second": 120,
            "amount": -1
        },
        {
            "first": 117,
            "second": 42,
            "amount": -1
        },
        {
            "first": 117,
            "second": 84,
            "amount": -1
        },
        {
            "first": 117,
            "second": 86,
            "amount": -1
        },
        {
            "first": 117,
            "second": 89,
            "amount": -2
        },
        {
            "first": 118,
            "second": 42,
            "amount": -1
        },
        {
            "first": 118,
            "second": 44,
            "amount": -5
        },
        {
            "first": 118,
            "second": 45,
            "amount": -1
        },
        {
            "first": 118,
            "second": 46,
            "amount": -5
        },
        {
            "first": 118,
            "second": 47,
            "amount": -1
        },
        {
            "first": 118,
            "second": 65,
            "amount": -1
        },
        {
            "first": 118,
            "second": 74,
            "amount": -3
        },
        {
            "first": 118,
            "second": 84,
            "amount": -1
        },
        {
            "first": 118,
            "second": 86,
            "amount": -1
        },
        {
            "first": 118,
            "second": 89,
            "amount": -1
        },
        {
            "first": 118,
            "second": 90,
            "amount": -1
        },
        {
            "first": 118,
            "second": 97,
            "amount": -1
        },
        {
            "first": 118,
            "second": 99,
            "amount": -1
        },
        {
            "first": 118,
            "second": 100,
            "amount": -1
        },
        {
            "first": 118,
            "second": 101,
            "amount": -1
        },
        {
            "first": 118,
            "second": 106,
            "amount": -1
        },
        {
            "first": 118,
            "second": 111,
            "amount": -1
        },
        {
            "first": 118,
            "second": 113,
            "amount": -1
        },
        {
            "first": 118,
            "second": 122,
            "amount": -2
        },
        {
            "first": 119,
            "second": 42,
            "amount": -1
        },
        {
            "first": 119,
            "second": 44,
            "amount": -7
        },
        {
            "first": 119,
            "second": 46,
            "amount": -7
        },
        {
            "first": 119,
            "second": 65,
            "amount": -1
        },
        {
            "first": 119,
            "second": 74,
            "amount": -2
        },
        {
            "first": 119,
            "second": 84,
            "amount": -2
        },
        {
            "first": 119,
            "second": 86,
            "amount": -1
        },
        {
            "first": 119,
            "second": 88,
            "amount": -1
        },
        {
            "first": 119,
            "second": 89,
            "amount": -2
        },
        {
            "first": 119,
            "second": 97,
            "amount": -1
        },
        {
            "first": 119,
            "second": 99,
            "amount": -1
        },
        {
            "first": 119,
            "second": 100,
            "amount": -1
        },
        {
            "first": 119,
            "second": 101,
            "amount": -1
        },
        {
            "first": 119,
            "second": 106,
            "amount": -1
        },
        {
            "first": 119,
            "second": 111,
            "amount": -1
        },
        {
            "first": 119,
            "second": 113,
            "amount": -1
        },
        {
            "first": 119,
            "second": 122,
            "amount": -2
        },
        {
            "first": 120,
            "second": 33,
            "amount": -1
        },
        {
            "first": 120,
            "second": 42,
            "amount": -1
        },
        {
            "first": 120,
            "second": 44,
            "amount": 1
        },
        {
            "first": 120,
            "second": 45,
            "amount": -1
        },
        {
            "first": 120,
            "second": 46,
            "amount": -1
        },
        {
            "first": 120,
            "second": 59,
            "amount": 1
        },
        {
            "first": 120,
            "second": 67,
            "amount": -1
        },
        {
            "first": 120,
            "second": 71,
            "amount": -1
        },
        {
            "first": 120,
            "second": 79,
            "amount": -1
        },
        {
            "first": 120,
            "second": 81,
            "amount": -1
        },
        {
            "first": 120,
            "second": 83,
            "amount": -1
        },
        {
            "first": 120,
            "second": 84,
            "amount": -1
        },
        {
            "first": 120,
            "second": 86,
            "amount": -1
        },
        {
            "first": 120,
            "second": 88,
            "amount": -1
        },
        {
            "first": 120,
            "second": 89,
            "amount": -3
        },
        {
            "first": 120,
            "second": 97,
            "amount": -1
        },
        {
            "first": 120,
            "second": 99,
            "amount": -2
        },
        {
            "first": 120,
            "second": 100,
            "amount": -2
        },
        {
            "first": 120,
            "second": 101,
            "amount": -2
        },
        {
            "first": 120,
            "second": 111,
            "amount": -2
        },
        {
            "first": 120,
            "second": 113,
            "amount": -2
        },
        {
            "first": 120,
            "second": 116,
            "amount": -2
        },
        {
            "first": 120,
            "second": 121,
            "amount": -1
        },
        {
            "first": 121,
            "second": 44,
            "amount": -5
        },
        {
            "first": 121,
            "second": 46,
            "amount": -5
        },
        {
            "first": 121,
            "second": 74,
            "amount": -3
        },
        {
            "first": 121,
            "second": 84,
            "amount": -1
        },
        {
            "first": 121,
            "second": 86,
            "amount": -1
        },
        {
            "first": 121,
            "second": 88,
            "amount": -1
        },
        {
            "first": 121,
            "second": 89,
            "amount": -1
        },
        {
            "first": 121,
            "second": 97,
            "amount": -1
        },
        {
            "first": 121,
            "second": 99,
            "amount": -1
        },
        {
            "first": 121,
            "second": 100,
            "amount": -1
        },
        {
            "first": 121,
            "second": 101,
            "amount": -1
        },
        {
            "first": 121,
            "second": 106,
            "amount": -1
        },
        {
            "first": 121,
            "second": 111,
            "amount": -1
        },
        {
            "first": 121,
            "second": 113,
            "amount": -1
        },
        {
            "first": 121,
            "second": 120,
            "amount": -1
        },
        {
            "first": 121,
            "second": 122,
            "amount": -2
        },
        {
            "first": 122,
            "second": 45,
            "amount": -2
        },
        {
            "first": 122,
            "second": 84,
            "amount": -1
        },
        {
            "first": 122,
            "second": 89,
            "amount": -1
        },
        {
            "first": 122,
            "second": 97,
            "amount": -1
        },
        {
            "first": 122,
            "second": 99,
            "amount": -1
        },
        {
            "first": 122,
            "second": 100,
            "amount": -1
        },
        {
            "first": 122,
            "second": 101,
            "amount": -1
        },
        {
            "first": 122,
            "second": 111,
            "amount": -1
        },
        {
            "first": 122,
            "second": 113,
            "amount": -1
        },
        {
            "first": 122,
            "second": 117,
            "amount": -1
        },
        {
            "first": 122,
            "second": 118,
            "amount": -1
        },
        {
            "first": 122,
            "second": 121,
            "amount": -1
        },
        {
            "first": 123,
            "second": 74,
            "amount": -1
        },
        {
            "first": 123,
            "second": 106,
            "amount": 6
        }
    ]
};